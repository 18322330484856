import request from "../utils/request";

export const setkork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "exhibit/collect",
        method: "post",
        data: query,
    });
};

export const setkorknot = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "exhibit/collectnot",
        method: "post",
        data: query,
    });
};


export const findCollect = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "exhibit/collect/find",
        method: "get",
        params: query,
    });
};

export const addComment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "exhibit/comment/add",
        method: "post",
        data: query,
    });
};

export const findComment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "exhibit/comment/find",
        method: "get",
        params: query,
    });
};

export const findSkip = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "map/fixed/list",
        method: "get",
        params: query,
    });
};

export const findSearch = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "map/fixed/keyWord",
        method: "get",
        params: query,
    });
};

export const base64Img = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "image/base64Img",
        method: "post",
        data: query,
    });
};


export const base64QR = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "image/qr",
        method: "get",
        params: query,
    });
};

export const setshare = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "exhibit/share/add",
        method: "post",
        data: query,
    });
};



//添加热点搜索
export const  popularAdd= (query) => {
    return request({
        url: process.env.VUE_APP_URL + "create/popular/add",
        method: "post",
        data: query,
    });
};

export const  popularFind= (query) => {
    return request({
        url: process.env.VUE_APP_URL + "create/popular/find",
        method: "get",
        data: query,
    });
};



//打开证据
export const chainDetailUrl = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "CanvasAdmin/Chain/DetailUrl",
        method: "get",
        params: query,
    });
};

export const addEvaluate = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "evaluate/work/add",
        method: "post",
        data: query,
    });
};

export const findEvaluate = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "evaluate/work/find",
        method: "get",
        params: query,
    });
};

