<template>
  <div id="barrage">

  </div>
</template>

<script>
import AwesomeDanmaku from 'awesome-danmaku'
import {findComment} from '../api/exhibit'

export default {
  data() {
    return {
      data: []
    }
  },
  beforeDestroy() {
    if (this.data.length >= 1) {
      this.maps.stop()
      clearInterval( this.set)
    }

    setTimeout(() => {
      location.reload()
    }, 10)


  },
  mounted() {
    if ( this.$store.state.barrage){
      this.$store.state.barrage=false
    }

    this.maps = AwesomeDanmaku.getPlayer({
      el: '#barrage',
      maxCount: 100,
      trackCount: 100,
      nodeMaxCount: 100,
      rollingTime: 5000
    });
    this.findcomment()
    this.maps.play()
  },
  methods: {
    pay(){
      this.maps.play()
    },
    stop(){
      this.maps.stop()
    },
    findcomment() {
      if (this.data.length>=1){
        clearInterval( this.set)
      }
      findComment().then(res => {
        let sum = res.sum
        if (sum.length >= 1) {
          this.data = sum
          this.set = setInterval(() => {
            for (let item in this.data) {
              this.maps.insert({value: sum[item].comment, color: '#fff', fontSize: 10});
            }
          }, 1000)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
p {
  font-size: 14px !important;
}
</style>
