<template>
  <div class="list" ref="list">
    <div v-if="!popular">
      <div class="block" v-for="(item ,index) in data" :key="index">
        <div class="img"
             @click="openImg(item.imageUrl, item.name,item.collect, item.time ,item.penName,item.SidNumber,titleNumber,item.evidenceId)">
          <img
              :src="item.imageUrl"></div>
        <!--          @click="opensetMap(item.SidNumber)"-->
        <div class="number">编号：{{ item.SidNumber }}</div>
        <div class="user">作者：{{ item.name }}</div>
        <div class="title">标题：{{ item.penName }}</div>
        <div class="time">时间：{{ item.times }}</div>
        <div class="great" v-bind:class="{greats: item.Collects}"><i
            @click="work(item._id,item.user_id)"></i><span>{{ item.collect }}</span></div>
        <div class="step" v-bind:class="{steps: item.CollectNots}"><i
            @click="worknot(item._id,item.user_id)"></i><span>不喜欢</span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="blocks" v-for="(item ,index) in data" :key="index">
        <div class="img"
             @click="openImg(item.imageUrl, item.name,item.collect, item.time ,item.penName,item.SidNumber,titleNumber,item.evidenceId)">
          <img
              :src="item.imageUrl"></div>
        <div class="number">编号：{{ item.SidNumber }}</div>
        <div class="user">作者：{{ item.name }}</div>
        <div class="title">标题：{{ item.penName }}</div>
        <div class="time">时间：{{ item.times }}</div>
        <div class="cham">
          <i v-bind:class="{ones:index==0,twos:index==1,threes:index==2,oneT:item.Coll=='oneT',twoT:item.Coll=='twoT',threeT:item.Coll=='threeT'}"
             v-if="index==0||index==1||index==2"
             @click="work(item._id,item.user_id)"></i>
          <i v-else class="like" v-bind:class="{Ts:item.Coll=='Ts',T:item.Coll=='T'}"
             @click="work(item._id,item.user_id)"></i>
          <span>{{ item.collect }}</span>
        </div>
        <div class="step" v-bind:class="{steps: item.Colls==1}"><i
            @click="worknot(item._id,item.user_id)"></i><span>不喜欢</span>
        </div>
      </div>

    </div>

    <div class="addLoad" v-if="data.length>=1&&!Search&&!popular">
      <span @click="LastLoad" class="page">上一页</span>
      <span class="pages">{{ skip }}/{{ pageTol }}</span>
      <span @click="NextLoad" class="page">下一页</span>
    </div>
    <div class="NotData" v-if="data.length==0" v-bind:style="NotData">
      暂无数据
    </div>
    <Load ref="Load"></Load>
    <Details ref="Details"></Details>
  </div>
</template>

<script>
import {findSkip, findCollect, setkork, setkorknot, popularFind} from "../api/exhibit";
import {Toast} from "vant";
import Load from "./load";
import Details from "./Details";

export default {
  data() {
    return {
      skip: 1,
      pageTol: 0,
      titleNumber: '',
      data: [],
      Search: false,
      popular: false,
      NotData: '',
      filoink: {}
    }
  },
  components: {
    Load,
    Details
  },
  mounted() {
    let list = this.$refs.list
    let height = list.style.height
    this.NotData = 'line-height:' + height
  },
  methods: {
    init(titleNumber, filoink) {
      this.Search = false
      this.titleNumber = titleNumber
      this.skip = 1
      this.pageTol = 0
      this.fachdata()
      this.popular = false
      this.filoink = filoink
    },
    openPopular() {
      this.$refs.Load.open()
      this.popular = true
      this.data = []
      this.uppopularCol()
    },
    //打开详情
    openImg(str, name, collect, time, penName, SidNumber, titleNumber, evidenceId) {
      this.$refs.Details.openImg(str, name, collect, time, penName, SidNumber, titleNumber, evidenceId)
    },
    inSearch(data) {
      this.Search = true
      this.UpCollect(data)
    },
    UpCollects(data) {
      let userInfo = this.$store.state.userInfo
      let _id = userInfo._id
      findCollect({_id}).then(res => {
        let Collect = res.Collect
        let CollectNot = res.CollectNot
        for (let item in data) {
          for (let i in Collect) {
            let SidNumber = data[item].SidNumber
            if (item == 0) {
              data[item].Coll = 'one'
            } else if (item == 1) {
              data[item].Coll = 'two'
            } else if (item == 2) {
              data[item].Coll = 'three'
            } else {
              data[item].Coll = 'T'
            }

            if (SidNumber == Collect[i].SidNumber) {
              if (item == 0) {
                data[item].Coll = 'oneT'
              } else if (item == 1) {
                data[item].Coll = 'twoT'
              } else if (item == 2) {
                data[item].Coll = 'threeT'
              } else {
                data[item].Coll = 'Ts'
              }
              break;
            }
          }
          for (let i in CollectNot) {
            let SidNumber = data[item].SidNumber
            if (SidNumber == CollectNot[i].SidNumber) {
              data[item].Colls = 1
              break;
            } else {
              data[item].Colls = 0
              break;
            }
          }
        }
        this.data = data
      })
    },
    //查询点赞与点踩记录并更新视图
    UpCollect(data) {
      let userInfo = this.$store.state.userInfo
      let _id = userInfo._id
      if (_id) {
        findCollect({_id}).then(res => {
          let Collect = res.Collect
          let CollectNot = res.CollectNot
          for (let item in data) {
            let name = data[item].name
            let penName = data[item].penName
            if (name.length >= 5) {
              data[item].names = name.slice(0, 5) + '...'
            } else {
              data[item].names = name
            }
            if (penName.length >= 5) {
              data[item].penNames = penName.slice(0, 5) + '...'
            } else {
              data[item].penNames = penName
            }

            for (let i in Collect) {
              if (Collect[i].SidNumber == data[item].SidNumber) {
                data[item].Collects = true
                break;
              }
            }
            for (let i in CollectNot) {
              if (CollectNot[i].SidNumber == data[item].SidNumber) {
                data[item].CollectNots = true
                break;
              }
            }
          }
          this.data = data
        })
      } else {
        this.data = data
      }
    },
    //点踩
    worknot(work_id, user_ids) {
      let userInfo = this.$store.state.userInfo
      let user_id = userInfo._id
      let name = userInfo.Name

      if (user_id) {
        setkorknot({work_id, user_id, user_ids, name}).then(res => {
          if (res.code == 0) {
            Toast.success(res.message);
            if (this.popular) {
              this.uppopularCol()
            } else {
              this.fachdata(1)
            }
          } else {
            Toast.fail(res.message);
          }
        })
      } else {
        Toast('请登录账号才能进行操作');
      }
    },
    uppopularCol() {
      popularFind().then(res => {
        this.$refs.Load.close()
        let data = res.data
        this.UpCollects(data)
      })
    },
    //点赞
    work(work_id, user_ids) {
      let userInfo = this.$store.state.userInfo
      let user_id = userInfo._id
      let name = userInfo.Name

      if (user_id) {
        setkork({work_id, user_id, user_ids, name}).then(res => {
          if (res.code == 0) {
            Toast.success(res.message);
            if (this.popular) {
              this.uppopularCol()
            } else {
              this.fachdata(1)
            }
          } else {
            Toast.fail(res.message);
          }
        })
      } else {
        Toast('请登录账号才能进行操作');
      }


    },
    LastLoad() {
      if (this.skip == 1) {
        Toast('已经是第一页了～');
        return
      }
      this.skip--
      this.fachdata()
    },
    NextLoad() {
      if (this.skip == this.pageTol) {
        Toast('已经到底了～');
        return
      }
      this.skip++
      this.fachdata()
    },
    fachdata(str) {
      if (!str == 1) {
        this.data = []
      } else {
        this.$refs.Load.open()
      }
      let skip = this.skip
      let titleNumber = this.titleNumber


      findSkip({skip, titleNumber}).then(res => {

        this.$refs.Load.close()
        let number = res.number
        number = Math.ceil(number / 10)
        this.pageTol = number
        this.UpCollect(res.sum)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.list {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .blocks {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    position: relative;
    margin-bottom: 10px;
    @mixin pos {
      position: absolute;
      font-size: 11px;
    }

    .img {
      position: absolute;
      width: 60px;
      height: 85px;
      left: 30px;
      top: 6px;
      overflow: hidden;
      border: 1px solid #f0f0f0;

      img {
        //transform: rotate(90deg);
        width: 100%;
        height: 100%;
      }
    }

    .number {
      @include pos;
      left: 100px;
      top: 10px;
    }

    .title {
      @include pos;
      left: 100px;
      top: 50px;
    }

    .time {
      @include pos;
      left: 100px;
      top: 70px;
    }

    .user {
      @include pos;
      left: 100px;
      top: 30px;
      width: 120px;
    }

    .cham {
      @include pos;
      right: 75px;
      top: 10px;

      i {
        width: 17px;
        height: 17px;
        background-size: 100% 100%;
        margin-right: 10px;
        display: inline-block;
      }

      .Ts {
        background-image: url("../assets/image/create/likes.png");
        margin-left: -41px;
      }

      .T {
        margin-left: -41px;
        background-image: url("../assets/image/create/like.png");
      }

      .ones {
        background-image: url("../assets/image/exhibit/chamOnes.png");
      }

      .twos {
        background-image: url("../assets/image/exhibit/chamTwos.png");
      }

      .threes {
        margin-left: -41px;
        background-image: url("../assets/image/exhibit/chamThrees.png");
      }

      .oneT {
        background-image: url("../assets/image/exhibit/chamOne.png");
      }

      .twoT {
        background-image: url("../assets/image/exhibit/chamTwo.png");
      }

      .threeT {
        margin-left: -41px;
        background-image: url("../assets/image/exhibit/chamThree.png");
      }


    }

    .step {
      @include pos;
      right: 53px;
      top: 40px;


      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/step.png");
        width: 17px;
        height: 17px;
        background-size: 100% 100%;
        float: left;
      }

      span {
        float: left;
        display: inline-block;
      }
    }

    .steps {
      i {
        background-image: url("../assets/image/create/steps.png");
      }

    }
  }

  .block {
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    position: relative;
    margin-bottom: 10px;
    @mixin pos {
      position: absolute;
      font-size: 11px;
    }

    .img {
      position: absolute;
      width: 60px;
      height: 85px;
      left: 30px;
      top: 6px;
      overflow: hidden;
      border: 1px solid #f0f0f0;

      img {
        //transform: rotate(90deg);
        width: 100%;
        height: 100%;
      }
    }

    .number {
      @include pos;
      left: 100px;
      top: 10px;
    }

    .title {
      @include pos;
      left: 100px;
      top: 50px;
    }

    .time {
      @include pos;
      left: 100px;
      top: 70px;
    }

    .user {
      @include pos;
      left: 100px;
      top: 30px;
      width: 120px;
    }

    .great {
      @include pos;
      right: 75px;
      top: 10px;

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/like.png");
        width: 17px;
        height: 17px;
        background-size: 100% 100%;
      }
    }

    .greats {
      i {
        background-image: url("../assets/image/create/likes.png");
      }
    }

    .step {
      @include pos;
      right: 48px;
      top: 40px;


      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/step.png");
        width: 17px;
        height: 17px;
        background-size: 100% 100%;
        float: left;

      }

      span {
        float: left;
        display: inline-block;
      }
    }

    .steps {
      i {
        float: left;
        background-image: url("../assets/image/create/steps.png");
      }
    }
  }

  .addLoad {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 13px;
    color: #000;

    display: flex;
    justify-content: space-around;

    .page {
      color: #973733;
      cursor: pointer;
      user-select: none;
    }

    .pages {
      color: #666666;
      cursor: pointer;
      user-select: none;
    }
  }

  .NotData {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #666666;
    text-align: center;

  }
}

.list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.list::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.list::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #ededed;
}
</style>
